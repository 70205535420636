.table {
    table-layout: fixed;
}

img {
    width: 260px;
    padding: 10px;
}

.header {
    font-size: 1.875rem;
}

label {
    cursor: pointer;
}

.bold {
    font-weight: bold;
}

.header-text{
    background-color: #22b0bf;
    color: white;
    padding-left: 12px;
}

.tiny {
    font-size: 60%;
}

.col-center {
    width: 100%;
}

.button-col {
    width: 100%;
    border: 2px solid grey;
    height: 75px;
    font-size: xx-large;
    font-weight: 200;
    margin-bottom: 10px;
}

.wasteline-input {
    padding-left: .75rem;
    width: 100%;
}

.left-col {
    width: 30px;
    max-width: 30px;
}

@media screen and (min-width: 40em) {
    .left-col {
        width: 100px;
        max-width: 100px;
    }
}

@media screen and (min-width: 52em) {
    .left-col {
        width: 125px;
        max-width: 125px;
    }
}

.middle-col {
    width: 50px;
    max-width: 50px;
}

@media screen and (min-width: 40em) {
    .middle-col {
        width: 100px;
        max-width: 100px;
    }
}

@media screen and (min-width: 52em) {
    .middle-col {
        width: 125px;
        max-width: 125px;
    }
}

.right-col {
    width: 50px;
    max-width: 50px;
}

@media screen and (min-width: 40em) {
    .right-col {
        width: 100px;
        max-width: 100px;
    }
}

@media screen and (min-width: 52em) {
    .right-col {
        width: 125px;
        max-width: 125px;
    }
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
}

.App-header {
    background-color: #22b0bf;
    font-size: 2em;
    color: white;
    margin-bottom: 15px;
    height: 70px;
    padding: 10px;
}

a:visited {
    color: white;
}

a {
    color: white;
}

.header-line {
    flex-grow: 1;
    background-color: grey;
    color: azure;
}

.input-line {
    margin: 6px;
}

label {
    margin: 6px;
}

.calculated-result {
    text-align: right;
}

.wasteline-row {
    display: flex;
}

.wide-label {
    padding: .75rem;
    margin: 0;
    /*width: 500px;*/
}

.costline-container {
    display: flex;
    border: 1px solid #dee2e6;
    flex-direction: column;
    margin-bottom: 15px;
    height: 99px;
}

.costline-input {
    height: 50px;
    padding: .75rem;
    border: 0;
}

@media screen and (min-width: 52em) {
    .costline-container {
        flex-direction: row;
        height: 60px;
    }

    .costline-input {
        width: 100%;
        height: 100%;
    }

    .wide-label {
        width: 500px;
        height: 100%;
    }
}